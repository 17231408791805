import React, { useState, useEffect } from 'react';
import {
  NavLink, useSearchParams, useNavigate
} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'react-lottie-player';
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

// import { useSelector } from 'react-redux';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { useLazyQuery, gql } from '@apollo/client';

import { handleError } from '../../../../actions/networkActions';
// import philippineFlag from '../../../../assets/img/philippine flag.png';
import iconActions from './icon-actions.svg';
import empty from '../../../../assets/img/empty.png';
import loadingAnimation from '../../../../assets/lottie/loadingAnimation.json';
import iconChevronLeft from './icons-chevron-left.svg';
import iconChevronRight from './icons-chevron-right.svg';
import iconFirst from './icon-first.svg';
import iconLast from './icon-last.svg';

// import { faker } from '@faker-js/faker';
// const sampleThreats = Array.from({ length: 20 } , () => (
//   { 
//     id: faker.number.int(10000), 
//     name: `${faker.word.adjective()} ${faker.hacker.adjective()}`,
//     parentGroup: `${faker.word.adjective()} ${faker.hacker.adjective()}`,
//     description: `${faker.lorem.lines()}`,
//     date_created: faker.date.past(),
//     date_imported: faker.date.past() 
//   }
// ));
export const GET_THREATLIST = gql`
  query(
    $filter: String
    $page_index: Int
    $limit: Int
  ) {
    getThreatList(
      filter: $filter
      page_index: $page_index
      limit: $limit
    ) {
      data {
        uuid
        threat_id
        name
        parent_group
        description
        date_created
        date_imported
      }
      record_count
    }
  }
`;

function Threats() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [
    getThreatList,
    { data: dataGetThreatList, loading: loadingGetThreatList, error: errorGetThreatList, /* refetch: refetchThreatList */ },
  ] = useLazyQuery(GET_THREATLIST, { fetchPolicy: 'no-cache' });

  const initialPage = parseInt(searchParams.get("page")) || 0;

  const [totalResults, setTotalResults] = useState(0);
  const [filters, setFilters] = useState({
    // results: 0,
    pageCount: 0,
    pageSize: 10,
    offset: 0,
    page: initialPage,
    ids: [],
    names: [],
    types: [],
    authors: [],
    tags: [],
    search: '',
    sort_by: 'id',
    sort: 'DESC',
    startTime: moment().subtract(3, 'months').startOf('day'),
    endTime: moment().endOf('day'),
  });

  useEffect(() => {
    getThreatList({ variables: { filter: filters.search, page_index: filters.page, limit: filters.pageSize } });
  }, [getThreatList, filters.search, filters.page, filters.pageSize]);

  // useEffect(() => {
  //   const pageFromParams = parseInt(searchParams.get("page")) || 0;
  //   console.log(pageFromParams)
  //   if (pageFromParams !== filters.page) {
  //     setFilters({ ...filters, page: pageFromParams });
  //   }
  // }, [searchParams, filters]);

  useEffect(() => {
    const pageFromParams = parseInt(searchParams.get("page")) || 0;
    if (pageFromParams !== filters.page) {
      setSearchParams({ page: filters.page });
    }
  }, [setSearchParams, searchParams, filters.page]);

  useEffect(() => {
    if (!dataGetThreatList) {
      return;
    }
    console.log(dataGetThreatList)
    setTotalResults(dataGetThreatList.getThreatList?.record_count || 0);
  }, [dataGetThreatList, setTotalResults]);

  useEffect(() => {
    if (!errorGetThreatList) {
      return;
    }
    // console.log({ ...errorGetThreatList })
    handleError(errorGetThreatList.networkError?.response?.status);
  }, [errorGetThreatList]);

  const changeThreatsPerPage = (i) => {
    setFilters({
      ...filters,
      page: 0,
      offset: 0,
      pageSize: parseInt(i.target.value, 10),
    });
  };

  const handlePageClick = (i) => {
    const page = i.selected;
    const offset = Math.ceil(page * filters.pageSize);
    setFilters({ ...filters, page, offset });
  };

  const tableHeaders = <thead>
    <tr className="table-secondary">
      <th className="border-light position-sticky" style={{ top: '0', width: '30px', zIndex: 1 }}>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        </div>
      </th>
      <th className="border-light position-sticky" style={{ top: '0', width: '150px' }}>
        <span className="me-2">Threat ID</span>
        <FontAwesomeIcon icon="sort" />
      </th>
      <th className="border-light position-sticky" style={{ top: '0' }}>
        <span className="me-2">Name</span>
        <FontAwesomeIcon icon="sort" />
      </th>
      <th className="border-light position-sticky" style={{ top: '0' }}>
        <span className="me-2">Parent Group</span>
        <FontAwesomeIcon icon="sort" />
      </th>
      <th className="border-light position-sticky" style={{ top: '0' }}>
        <span className="me-2">Description</span>
        <FontAwesomeIcon icon="sort" />
      </th>
      <th className="border-light position-sticky" style={{ top: '0' }}>
        <span className="me-2">Date Created</span>
        <FontAwesomeIcon icon="sort" />
      </th>
      <th className="border-light position-sticky" style={{ top: '0' }}>
        <span className="me-2">Date Imported</span>
        <FontAwesomeIcon icon="sort" />
      </th>
      <th className="border-light position-sticky" style={{ top: '0', width: '70px' }}>Actions</th>
    </tr>
  </thead>;

  const filteredResults = dataGetThreatList?.getThreatList?.data.length || 0;

  return (
    <div id="threats" className="animated fadeIn flex-grow-1 d-flex flex-column" style={{ minHeight: 'calc(100vh - 260px)' }}>
      <Helmet>
        <title>{`Project VSEPR: Data Browser - Threats`}</title>
      </Helmet>

      <div className="animated fadeIn flex-grow-1 d-flex" style={{ backgroundColor: '#F8F8F8' }}>
        <div className="mt-3 mb-4 flex-grow-1 d-flex">
          <div className="container-fluid d-flex flex-column">

            <div className="mb-0">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <h5 className="fw-bold mb-0">Threats <span className="text-muted fw-normal ms-3">{totalResults} result/s</span></h5>
                </div>
              </div>
            </div>
            <div className="mb-0">
              <div className="row align-items-center justify-content-between" style={{ minHeight: '60px' }}>

                <div className="col-auto">
                  <div className="input-group">
                    <label className="input-group-text bg-white border-right-0" htmlFor="searchThreat"><FontAwesomeIcon icon="search" /></label>
                    <DebounceInput
                      className="form-control border-left-0"
                      id="searchThreat"
                      placeholder="Search in Threat Database"
                      style={{ width: '20rem' }}
                      debounceTimeout={1000}
                      autoComplete="off"
                      onChange={e => setFilters({ ...filters, search: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-auto">
                  <div className="row">

                    <div className="col-auto">
                      <button className="btn btn-light" disabled>
                        <FontAwesomeIcon icon="filter" className="text-muted" />
                      </button>
                    </div>

                    <div className="col-auto p-0 my-2 mx-2" style={{ borderLeft: '1px solid #CCCCCC' }}>
                    </div>

                    <div className="col-auto">
                      <div className="input-group">
                        <label htmlFor="page" className="form-label input-group-text mb-0 border-0 ps-0 bg-transparent">Rows:</label>
                        <select id="page" className="form-select rounded" value={filters.pageSize} onChange={changeThreatsPerPage}>
                          <option value={1}>1</option>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>

                    {/* Pagination */}
                    <nav className="col-auto d-flex align-items-center">
                      <button className={`px-2 rounded-0 me-2 btn btn-outline-secondary d-flex align-items-center ${filters.page === 0 ? 'disabled border-light' : ''}`} onClick={() => { handlePageClick({ selected: 0 }); }}>
                        <img src={iconFirst} width={20} alt="first" />
                      </button>
                      <ReactPaginate
                        previousLabel={<img src={iconChevronLeft} width={20} alt="previous" />}
                        nextLabel={<img src={iconChevronRight} width={20} alt="next" />}
                        pageClassName="page-item"
                        previousLinkClassName="px-2 rounded-0 me-1 btn btn-outline-secondary d-flex align-items-center"
                        nextLinkClassName="px-2 rounded-0 ms-1 btn btn-outline-secondary d-flex align-items-center"
                        pageLinkClassName="px-2 rounded-0 mx-1 btn btn-outline-secondary d-flex align-items-center"
                        breakLinkClassName="px-2 rounded-0 mx-1 btn btn-outline-secondary d-flex align-items-center"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={Math.ceil(totalResults / filters.pageSize)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName="pagination mb-0"
                        activeClassName="active"
                        activeLinkClassName="active text-white"
                        disabledLinkClassName="disabled border-light"
                        forcePage={filters.page}
                      />
                      <button className={`px-2 rounded-0 ms-2 btn btn-outline-secondary d-flex align-items-center ${filters.page === Math.ceil(totalResults / filters.pageSize) - 1 ? 'disabled border-light' : ''}`} onClick={() => { handlePageClick({ selected: Math.ceil(totalResults / filters.pageSize) - 1 }); }}>
                        <img src={iconLast} width={20} alt="last" />
                      </button>
                    </nav>
                  </div>
                </div>

              </div>
            </div>

            {/* {(filters.results > 0 && !loading) && */}
            {(filteredResults > 0 && !loadingGetThreatList) &&
              <div className="flex-grow-1 overflow-auto" style={{ height: 'calc(100vh - 395px)' }}>
                <table className="table mb-0 table-hover">
                  {tableHeaders}
                  <tbody className="bg-white">
                    {dataGetThreatList.getThreatList.data.map((threat, idx) => {
                      return (
                        <ThreatListItem key={`threat-${threat.uuid}`} threat={threat} />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            }
            {(totalResults > 0 && filteredResults === 0 && !loadingGetThreatList) &&
              <div className="d-flex flex-column flex-grow-1 overflow-auto" style={{ height: 'calc(100vh - 395px)' }}>
                <table className="table mb-0">
                  {tableHeaders}
                </table>
                <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img alt="" src={empty} width={150} />
                    <h5 className="fw-bold mb-3">We can't find the threat<br />you're looking for.</h5>
                    <p>Try checking for typos, or check if you have<br />entered the right name.</p>
                  </div>
                </div>
              </div>
            }
            {(totalResults === 0 && !loadingGetThreatList) &&
              <div className="d-flex flex-column flex-grow-1 overflow-auto" style={{ height: 'calc(100vh - 395px)' }}>
                <table className="table mb-0">
                  {tableHeaders}
                </table>
                <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <img alt="" src={empty} width={150} />
                    <h5 className="fw-bold mb-3">We can't find the threat<br />you're looking for.</h5>
                    <p>Try checking for typos, or check if you have<br />entered the right name.</p>
                  </div>
                </div>
              </div>
            }
            {loadingGetThreatList &&
              <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <Lottie
                    className="mt-2 mb-4"
                    loop
                    animationData={loadingAnimation}
                    speed={0.75}
                    play
                    style={{ width: 300, height: 300, margin: "0 auto" }}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div>

    </div>
  );
};

function ThreatListItem({ threat }) {
  const navigate = useNavigate()
  const navigateToThreat = () => {
    const to = `/dataextraction/databrowser/threats/${threat.uuid}/${threat.threat_id}`;
    navigate(to);
    // window.open(to, '_blank');
  };

  return (
    <tr onClick={navigateToThreat} className="cursor-pointer">
      <td>
        <div className="form-check" onClick={e => e.stopPropagation()}>
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        </div>
      </td>
      <td>
        {/* <NavLink className="text-vsepr fw-bold" to={`/dataextraction/databrowser/threats/${threat.uuid}/${threat.threat_id}`} end>{threat.threat_id}</NavLink> */}
        <span className="text-vsepr fw-bold">{threat.threat_id}</span>
      </td>
      <td className="text-capitalize">{threat.name}</td>
      <td className="text-capitalize">{(threat.parent_group ? threat.parent_group : '-')}</td>
      <td>{ threat.description}</td>
      <td>{moment(threat.date_created).format('DD-MMMM-YYYY HH:mm A') === "Invalid date" ? "---" : moment(threat.date_created).format('DD-MMMM-YYYY HH:mm A')}</td>
      <td>{moment(threat.date_imported).format('DD-MMMM-YYYY HH:mm A') === "Invalid date" ? "---" : moment(threat.date_imported).format('DD-MMMM-YYYY HH:mm A')}</td>
      <td>
        <div onClick={e => e.stopPropagation()}>
          <UncontrolledDropdown className="position-static">
            <DropdownToggle color="outline-light">
              <img alt="" src={iconActions} style={{ pointerEvents: 'none' }} />
            </DropdownToggle>
            <DropdownMenu className="py-1" style={{ minWidth: '100px' }}>
              <NavLink className="px-3 py-1 btn btn-sm btn-block text-left border-0 btn-outline-vsepr rounded-0" to={`/dataextraction/databrowser/threats/${threat.uuid}/${threat.threat_id}`}>View Threat</NavLink>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </td>
    </tr>
  )
};

export default Threats;